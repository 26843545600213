import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { wrapper } from '../services/store';
import { Flex } from 'rebass/styled-components';
import Layout from 'components/Layout';
import ContentWrapper from 'components/ContentWrapper';
import Tagline from 'components/Tagline';
import SectionHeading from 'components/SectionHeading';
import Landing from 'components/Landing';
import Divider from 'components/Divider';
import WhatWeDo from 'components/WhatWeDo';
import HowWeDoIt from 'components/HowWeDoIt';
import { getPosts, getSiteMeta } from '../services/actions';
import { get } from 'lodash';

const Home = ({ posts, tags, mediaCategories, categories }) => {
    const featuredTagId = tags.items.length
        ? get(tags.items.find((t) => t.slug === 'featured'), 'id')
        : undefined;

    const workCategoryId = categories.items.length
        ? get(categories.items.find((t) => t.slug === 'work'), 'id')
        : undefined;

    const featuredWork = posts.items.length
        ? posts.items.filter(
            (p) =>
                p.tags.includes(featuredTagId) &&
                p.categories.includes(workCategoryId)
        )
        : [];

    const carouselCategories = mediaCategories.items.length
        ? mediaCategories.items
            .filter((c) => c.slug === 'carousel')
            .map((c) => c.id)
        : [];

    const featuredImageCategory = mediaCategories.items.length
        ? mediaCategories.items
            .filter((c) => c.slug === 'cover')
            .map((c) => c.id)[0]
        : [];

    return (
        <Layout>
            <Landing />
            <ContentWrapper $fullHeight>
                <WhatWeDo title="What We Do" subtitle="and love doing" />
            </ContentWrapper>
            <ContentWrapper $background>
                <HowWeDoIt />
            </ContentWrapper>
        </Layout>
    );
};

Home.propTypes = {
    categories: PropTypes.shape({
        items: PropTypes.array,
    }),
    posts: PropTypes.shape({
        items: PropTypes.array,
    }),
    tags: PropTypes.shape({
        items: PropTypes.array,
    }),
    mediaCategories: PropTypes.shape({
        items: PropTypes.array,
    }),
};

// Use getServerSideProps to fetch data on the server
export const getServerSideProps = wrapper.getServerSideProps(
    (store) => async (context) => {
        const options = {
            categories: ['work'],
            tags: ['featured'],
        };

        // Dispatch actions to fetch data
        await store.dispatch(getSiteMeta());
        await store.dispatch(getPosts(options));

        // Return the state needed for the component
        const state = store.getState();
        return {
            props: {
                posts: state.posts,
                tags: state.tags,
                mediaCategories: state.mediaCategories,
                categories: state.categories,
            },
        };
    }
);

export default connect((state) => state)(Home);