import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Flex, Box, Text } from 'rebass/styled-components'
import AnimatedDrop from 'components/AnimatedDrop'
import Divider from 'components/Divider'
import { Waypoint } from 'react-waypoint'
import SectionHeading from './SectionHeading'
import Tagline from 'components/Tagline'

const fallback = [
  {
    title: 'Strategy',
    text: [
      'Digital Strategy',
      'Brand Strategy',
      'Product/Market Analysis',
      'User Research',
      'Creative & Content Planning',
    ],
  },
  {
    title: 'Design',
    text: [
      'Product Design',
      'Brand Design',
      'Art Direction',
      'Visual Design',
      'User Experience',
      'User Interface',
    ],
  },
  {
    title: 'Development',
    text: [
      'Mobile & Web Apps',
      'Full Stack Engineering',
      'Architecture & API',
      'Connected Devices/IoT',
      'Automated Testing',
      'DevOps & Infrastructure',
    ],
  },
  {
    title: 'Growth',
    text: [
      'Marketing',
      'Data Analytics',
      'Search Engine Optimization',
      'Email Marketing',
      'Ad Campaigns',
      'Content Creation',
    ],
  },
]

const DescriptionText = styled(Text)`
  font-family: ${(props) => props.theme.fonts.serif};
  color: ${(props) => props.theme.colors.gray1};
  font-size: 1.2rem;
  margin-bottom: ${(props) => props.theme.space[2]}px;
  position: relative;
  text-align: center;
`

class WhatWeDo extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  handleEnter = () => {
    this.setState({ show: true })
  }

  handleClick = (ev, item) => {
    const { onClick } = this.props
    if (onClick) {
      onClick(item.title.toLowerCase())
    }
  }

  render () {
    const { show } = this.state
    const { title, subtitle, data } = this.props
    const content = data || fallback
    return (
      <Flex flexDirection='column' py={4} textAlign='center'>
        <Waypoint onEnter={this.handleEnter} bottomOffset='10px' />
        <SectionHeading center>{title}</SectionHeading>
        <Tagline>{subtitle}</Tagline>
        <Divider medium />
        <Flex flexWrap='wrap' justifyContent='center'>
          {content.map((item, index) => (
            <Box
              key={item.title}
              onMouseEnter={(e) => this.handleClick(e, item)}
              flex='0 1 auto'
              width={[1, 1, 1 / 2, 1 / 4]}
              p={2}
            >
              {show && <AnimatedDrop item={item.title} delay={index * 150} />}
              <Box>
                {!data &&
                  item.text.map((t) => (
                    <DescriptionText key={t} k={t}>
                      {t}
                    </DescriptionText>
                  ))}
                {data && (
                  <DescriptionText key={item.text} k={item.text}>
                    {item.text}
                  </DescriptionText>
                )}
              </Box>
            </Box>
          ))}
        </Flex>
      </Flex>
    )
  }
}

WhatWeDo.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
    }),
  ),
}

export default WhatWeDo
