import styled from 'styled-components'
import React, { Component } from 'react'
import { Flex, Box } from 'rebass/styled-components'
import Logo from 'components/Logo'
import SectionHeading from 'components/SectionHeading'
import Tagline from 'components/Tagline'
import without from 'lodash/without'
import anime from 'animejs'
import { Waypoint } from 'react-waypoint'
import { WHITE_COLOR, ACCENT1_COLOR } from '../services/constants'
import { transparentize } from 'polished'

let animation

const RADIUS = 125
const DIAG_LEN = 40
const DIAG_MOVE = DIAG_LEN / Math.sqrt(2)
const DIAG_OFFSET = (RADIUS - DIAG_LEN / 2) / Math.sqrt(2)
const BOUNDS = 500
const CENTER = BOUNDS / 2

const strategyText = `
  Collaboration starts wherever you are — from concept to launch to growth.  We’ll craft a unified strategy focused on how design and technology can impressively resolve your challenges.
`

const developmentText = `
  Our experienced engineers will deliver the optimal solution for your
  technical challenges, the first time.
`

const designText = `
  We listen and research. Then we create experiences centered on the promise of your brand. With designs that deepen the connection with your audience. With details that intensify every interaction.
`

const growthText = `
  We’ll capture actionable data and make it accessible. Data that drives decisions. From identifying which markets to target, to uncovering the features your users have been looking for.
`

const textSections = [
  { key: 'north', text: strategyText },
  { key: 'east', text: designText },
  { key: 'south', text: growthText },
  { key: 'west', text: developmentText },
]

const animateIn = (el) => {
  animation = anime.timeline()
  animation
    .add({
      targets: el.circleMain,
      r: RADIUS,
      opacity: 1,
      duration: 500,
      strokeWidth: 1,
    })
    .add({
      targets: [
        el.segmentN,
        el.segmentE,
        el.segmentS,
        el.segmentW,
        el.segmentNE,
        el.segmentSE,
        el.segmentSW,
        el.segmentNW,
      ],
      strokeDashoffset: [anime.setDashoffset, 0],
      opacity: 1,
      easing: 'easeInOutSine',
      duration: 500,
      offset: '-=200',
    })
    .add({
      targets: [el.headingN, el.headingE, el.headingS, el.headingW],
      opacity: [0, 1],
      duration: 100,
    })
    .add({
      targets: [el.circleN, el.circleE, el.circleS, el.circleW],
      r: '7%',
      opacity: 1,
      duration: 300,
      easing: 'easeOutQuad',
      strokeWidth: 1,
      begin: () => {
        setTimeout(() => {
          el.svgGroup?.setAttribute('mask', 'url(#main-mask)')
        }, 50)
      },
    })
    .add({
      targets: [el.textN, el.textE, el.textS, el.textW],
      opacity: [0, 1],
    })

  animation.finished.then(() => {
    anime({
      targets: el.compass,
      loop: true,
      rotate: [0, -1, 1],
      direction: 'alternate',
      duration: 5000,
      easing: 'easeInQuad',
      elasticity: 100,
      // delay: 50
    })
  })
}

const animatehandleToggle = (el, state) => {
  if (state) {
    anime({
      targets: el,
      easing: 'easeOutSine',
      fill: ACCENT1_COLOR,
    })
  } else {
    anime({
      targets: el,
      easing: 'easeInSine',
      fill: transparentize(1, ACCENT1_COLOR),
    })
  }
}

const clearToggles = (elems) => {
  anime({
    targets: elems,
    easing: 'easeInSine',
    fill: transparentize(1, ACCENT1_COLOR),
  })
}

const svgProps = {
  viewBox: `0 0 ${BOUNDS} ${BOUNDS}`,
  width: '100%',
  height: '100%',
  preserveAspectRatio: 'xMidYMid',
  overflow: 'visible',
}

const circleBaseProps = {
  fill: 'none',
  stroke: WHITE_COLOR,
  opacity: 0,
  r: '0',
}

const compassCircleBaseProps = {
  r: '0',
  stroke: ACCENT1_COLOR,
  cursor: 'pointer',
  fill: transparentize(1, ACCENT1_COLOR),
}

const LogoContainer = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3em;
  transform: translate3d(-50%, -2.5em, 0);
  display: block;
  @media (max-width: ${(props) => props.theme.breakpoints[2]}em) {
    display: none;
  }
`

const SmallLogoContainer = styled(Box)`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints[2]}em) {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -1em, 0);
  }
`

const Relative = styled(Box)`
  position: relative;
`

const textStyle = {
  letterSpacing: '0.02em',
  opacity: 0,
  fill: WHITE_COLOR,
  fontSize: '0.5rem',
  textTransform: 'uppercase',
  textAnchor: 'middle',
  dominantBaseline: 'central',
  pointerEvents: 'none',
}

const headingTextStyle = {
  ...textStyle,
  color: ACCENT1_COLOR,
  fontSize: '0.75rem',
  fontWeight: 300,
}

const segmentStyle = {
  stroke: WHITE_COLOR,
  opacity: 0,
}

const TextSection = styled(Tagline)`
  color: ${(props) => props.theme.colors.white} !important;
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  font-style: normal;
  transition: opacity 0.25s ease;
  min-height: 200px;

  @media (max-width: 32em) {
    font-size: ${(props) => props.theme.fontSizes[4]}px;
  }
`

class HowWeDoIt extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      text: {
        key: undefined,
      },
    }
  }
  handleEnter = () => {
    const { show, text } = this.state
    if (!show) {
      this.setState({ show: true })
      setTimeout(() => animateIn(this), 350)
      setTimeout(() => this.toggle('north', this.circleN), 2500)
      // This looks a little goofy, but keeping reference to the
      // key and the text field at the same time and want to
      // preserve order of auto advancing
      this.interval = setInterval(() => {
        const keyMap = {
          north: 'circleN',
          east: 'circleE',
          south: 'circleS',
          west: 'circleW',
        }
        const keys = textSections.map((i) => i.key)
        const currentIndex = keys.indexOf(text.key)
        const nextIndex = (currentIndex + 1) % keys.length
        const nextKey = textSections[nextIndex].key
        this.toggle(nextKey, this[keyMap[nextKey]])
      }, 5000)
    }
  }

  toggle = (t, el) => {
    this.resetOthers(el)
    const item = textSections.find((i) => i.key === t)
    const text = this.state.text.key === t ? {} : item
    this.setState(
      {
        text: text,
      },
      animatehandleToggle(el, text.text),
    )
  }

  resetOthers = (el) => {
    const elements = [this.circleN, this.circleE, this.circleS, this.circleW]

    clearToggles(without(elements, el))
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  handleToggle = (e, t, el) => {
    e.stopPropagation()
    e.preventDefault()
    // Stop the auto-advance if a user interacts manually
    clearInterval(this.interval)
    //console.log('[HowWeDoIt] handleToggle ', t)
    this.toggle(t, el)
  }
  render () {
    const { text } = this.state.text

    return (
      <Relative py={4}>
        <Box width={[1, '80%']} mx='auto'>
          <SectionHeading center white>
            {`Together, we'll form a team passionate about your vision.`}
          </SectionHeading>
        </Box>
        <Flex flexDirection='column' alignItems='start' p={[1, 1, 1, 4]}>
          <Waypoint onEnter={this.handleEnter} bottomOffset='100px' />
          <Flex
            textAlign='center'
            justifyContent='center'
            px={2}
            py={4}
            width={1}
          >
            <Box
              width={['100%', '100%', '75%', '50%']}
              textAlign='center'
              mb={4}
            >
              <Relative>
                <LogoContainer>
                  <Logo icon color={ACCENT1_COLOR} size='3em' />
                </LogoContainer>
                <SmallLogoContainer>
                  <Logo icon color={ACCENT1_COLOR} size='1em' />
                </SmallLogoContainer>
                <svg
                  {...svgProps}
                  ref={(c) => {
                    this.compass = c
                  }}
                >
                  <defs>
                    <mask id='main-mask'>
                      <rect
                        y='0%'
                        x='0%'
                        width='100%'
                        height='100%'
                        fill='white'
                      />
                      <circle cx='50%' cy='25%' r='7%' fill='black' />
                      <circle cx='75%' cy='50%' r='7%' fill='black' />
                      <circle cx='50%' cy='75%' r='7%' fill='black' />
                      <circle cx='25%' cy='50%' r='7%' fill='black' />
                    </mask>
                  </defs>
                  <circle
                    cx='50%'
                    cy='25%'
                    {...compassCircleBaseProps}
                    ref={(c) => {
                      this.circleN = c
                    }}
                    onClick={(e) => this.handleToggle(e, 'north', this.circleN)}
                  />
                  <circle
                    cx='75%'
                    cy='50%'
                    {...compassCircleBaseProps}
                    ref={(c) => {
                      this.circleE = c
                    }}
                    onClick={(e) => this.handleToggle(e, 'east', this.circleE)}
                  />
                  <circle
                    cx='50%'
                    cy='75%'
                    {...compassCircleBaseProps}
                    ref={(c) => {
                      this.circleS = c
                    }}
                    onClick={(e) => this.handleToggle(e, 'south', this.circleS)}
                  />
                  <circle
                    cx='25%'
                    cy='50%'
                    {...compassCircleBaseProps}
                    ref={(c) => {
                      this.circleW = c
                    }}
                    onClick={(e) => this.handleToggle(e, 'west', this.circleW)}
                  />
                  <g
                    ref={(c) => {
                      this.svgGroup = c
                    }}
                  >
                    <path
                      d='M250 200 V 20'
                      {...segmentStyle}
                      ref={(c) => {
                        this.segmentN = c
                      }}
                    />
                    <path
                      d='M300 250 H 480'
                      {...segmentStyle}
                      ref={(c) => {
                        this.segmentE = c
                      }}
                    />
                    <path
                      d='M250 300 V 480'
                      {...segmentStyle}
                      ref={(c) => {
                        this.segmentS = c
                      }}
                    />
                    <path
                      d='M200 250 H 20'
                      {...segmentStyle}
                      ref={(c) => {
                        this.segmentW = c
                      }}
                    />

                    <circle
                      cx='50%'
                      cy='50%'
                      {...circleBaseProps}
                      ref={(c) => {
                        this.circleMain = c
                      }}
                    />
                  </g>

                  <path
                    d={`M${CENTER + DIAG_OFFSET} ${CENTER -
                      DIAG_OFFSET} l${DIAG_MOVE} -${DIAG_MOVE}`}
                    {...segmentStyle}
                    ref={(c) => {
                      this.segmentNE = c
                    }}
                  />
                  <path
                    d={`M${CENTER + DIAG_OFFSET} ${CENTER +
                      DIAG_OFFSET} l${DIAG_MOVE} ${DIAG_MOVE}`}
                    {...segmentStyle}
                    ref={(c) => {
                      this.segmentSE = c
                    }}
                  />
                  <path
                    d={`M${CENTER - DIAG_OFFSET} ${CENTER +
                      DIAG_OFFSET} l-${DIAG_MOVE} ${DIAG_MOVE}`}
                    {...segmentStyle}
                    ref={(c) => {
                      this.segmentSW = c
                    }}
                  />
                  <path
                    d={`M${CENTER - DIAG_OFFSET} ${CENTER -
                      DIAG_OFFSET} l-${DIAG_MOVE} -${DIAG_MOVE}`}
                    {...segmentStyle}
                    ref={(c) => {
                      this.segmentNW = c
                    }}
                  />

                  <text
                    style={textStyle}
                    x='50%'
                    y='25%'
                    ref={(c) => {
                      this.textN = c
                    }}
                  >
                    Strategy
                  </text>
                  <text
                    style={textStyle}
                    x='75%'
                    y='50%'
                    ref={(c) => {
                      this.textE = c
                    }}
                  >
                    Design
                  </text>
                  <text
                    style={textStyle}
                    x='50%'
                    y='75%'
                    ref={(c) => {
                      this.textS = c
                    }}
                  >
                    Growth
                  </text>
                  <text
                    style={textStyle}
                    x='25%'
                    y='50%'
                    ref={(c) => {
                      this.textW = c
                    }}
                  >
                    Development
                  </text>

                  <text
                    style={headingTextStyle}
                    x='50%'
                    y='1%'
                    ref={(c) => {
                      this.headingN = c
                    }}
                  >
                    N
                  </text>
                  <text
                    style={headingTextStyle}
                    x='99%'
                    y='50%'
                    ref={(c) => {
                      this.headingE = c
                    }}
                  >
                    E
                  </text>
                  <text
                    style={headingTextStyle}
                    x='50%'
                    y='99%'
                    ref={(c) => {
                      this.headingS = c
                    }}
                  >
                    S
                  </text>
                  <text
                    style={headingTextStyle}
                    x='1%'
                    y='50%'
                    ref={(c) => {
                      this.headingW = c
                    }}
                  >
                    W
                  </text>
                </svg>
              </Relative>
            </Box>
          </Flex>
          <Box width={[1, '65%']} mx='auto'>
            <TextSection textAlign='center' mb={2}>
              {text}
            </TextSection>
          </Box>
        </Flex>
      </Relative>
    )
  }
}

export default HowWeDoIt
