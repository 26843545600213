import styled from 'styled-components'
import { Heading } from 'rebass/styled-components'

const Tagline = styled(Heading)`
  letter-spacing: 0.02em;
  font-family: ${(props) => props.theme.fonts.serif};
  font-weight: 300;
  font-style: italic;
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  color: ${(props) => props.theme.colors.gray1};
  ${(props) =>
    props.pb ? '' : `padding-bottom: ${props.theme.space[2]}px;`};
`

export default Tagline
