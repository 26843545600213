import styled from 'styled-components'
import ContentWrapper from 'components/ContentWrapper'
import Headline from 'components/Headline'
import { Heading, Flex, Box } from 'rebass/styled-components'
import { Waypoint } from 'react-waypoint'
import anime from 'animejs'
import React, { Component } from 'react'
import Logo from 'components/Logo'
import ChevronDownSvg from '../public/static/icon/chevron-down.svg'
import { scrollTo } from '../utils'
import MediaQuery from './MediaQuery'

const Wrapper = styled.div`
  height: 100vh;
  color: ${(props) => props.theme.colors.white};
`
const Subtitle = styled(Heading)`
  letter-spacing: ${(props) => props.theme.letterSpacing};
  text-transform: uppercase;
  font-weight: 100;
  opacity: 0;
`

const Letter = styled.span`
  display: inline-block;
  line-height: 1em;
`

const FillFlex = styled(Flex)`
  position: relative;
`

const TaglineWrapper = styled(Flex)`
  min-height: 100vh;
`

const Tagline = styled(Heading)`
  letter-spacing: 0.02em;
  font-family: ${(props) => props.theme.fonts.serif};
  font-weight: 300;
  color: ${(props) => props.theme.colors.gray1};
`

const ChevronDownWrapper = styled.div`
  position: absolute;
  bottom: 1em;
  right: 0;
  opacity: 0;
  width: 100%;
  text-align: center;
`

const ChevronDownBtn = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  &:active,
  &:focus {
    outline: 0;
  }
`

const ChevronDown = styled(ChevronDownSvg)`
  display: inline-block;
`

class Landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      height: undefined,
    }
    this.animationsRun = false
    this.animateSubtitle = this.animateSubtitle.bind(this)
    this.animateDownChevron = this.animateDownChevron.bind(this)
  }

  componentWillUnmount () {
    this.downChevronTimeline && this.downChevronTimeline.pause()
  }

  componentDidMount () {
    this.setState({
      height: window.innerHeight + 'px',
    })
  }

  animateSubtitle () {
    this.animationsRun = true
    const subtitleTimeline = anime.timeline()
    subtitleTimeline
      .add({
        targets: '.subtitle',
        opacity: 1,
      })
      .add({
        targets: '.subtitle .letter',
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 1200,
        delay: (el, i) => {
          return 800 + (300 / 24) * i // => Final will === 2300 (longest animation for logo)
        },
        offset: 0,
        complete: () => this.revealDownChevron(),
      })
  }

  revealDownChevron () {
    anime({
      targets: '.chevron-down',
      opacity: 1,
      duration: 1000,
      complete: () => this.animateDownChevron(),
    })
  }

  animateDownChevron () {
    this.downChevronTimeline = anime.timeline({
      autoplay: false,
      loop: true,
    })
    this.downChevronTimeline
      .add({
        targets: '.chevron-down',
        translateY: {
          value: ['0px', '-20px'],
          duration: 800,
          easing: 'easeOutQuad',
        },
      })
      .add({
        targets: '.chevron-down',
        translateY: {
          value: ['-20px', '0px'],
          duration: 800,
          easing: 'easeInQuad',
        },
      })
    this.downChevronTimeline.play()
  }

  render () {
    const { height } = this.state
    return (
      <Flex flexDirection='column'>
        <ContentWrapper
          $background={true}
          style={{ zIndex: 1, marginTop: '-5em' }}
          $noPadding
          $noOpacity
        >
          <Wrapper>
            <FillFlex
              alignItems='center'
              flexDirection='column'
              justifyContent='center'
              style={{ height }}
            >
              {height && (
                <Box>
                  <MediaQuery query='(min-width: 48.1em)'>
                    <Logo icon $size='180px' grow />
                  </MediaQuery>
                  <MediaQuery query='(max-width: 48em)'>
                    <Logo icon $size='90px' grow />
                  </MediaQuery>
                </Box>
              )}
              <Subtitle className='subtitle' mt={3} fontSize={[2, 4, 4]} center>
                {'product design & development'.split('').map((char, index) =>
                  char.match(/[a-z]|&/g) ? (
                    <Letter key={index} className='letter'>
                      {char}
                    </Letter>
                  ) : (
                    char
                  ),
                )}
              </Subtitle>
              <ChevronDownWrapper className='chevron-down'>
                <ChevronDownBtn onClick={() => scrollTo('secondPage', 500)}>
                  <ChevronDown />
                </ChevronDownBtn>
              </ChevronDownWrapper>
              <Waypoint
                onEnter={() => !this.animationsRun && this.animateSubtitle()}
              />
            </FillFlex>
          </Wrapper>
        </ContentWrapper>
        {/* <ContentWrapper id={'secondPage'}>
          <TaglineWrapper
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            py={4}
          >
            <Box width={['80%', '60%']} alignItems='center'>
              <Headline textAlign='center'>
                <span>Paused for business.</span>
              </Headline>
              <Tagline fontSize={[3, '28px']} textAlign='center'>
                Fount pursues projects out of passion. Our team is focused on personal endeavours at this time.
              </Tagline>
            </Box>
          </TaglineWrapper>
        </ContentWrapper>*/}
      </Flex>
    )
  }
}

export default Landing
