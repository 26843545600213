import anime from 'animejs'
import React, { Component } from 'react'
import { ACCENT1_COLOR, WHITE_COLOR, FONT_SANS } from '../services/constants'

let animation

const animateIn = (primaryEl, secondaryEl, textEl) => {
  animation = anime.timeline()
  animation
    .add({
      targets: primaryEl,
      opacity: [0.2, 0.75, 1],
      r: [0, 40, 35],
      duration: 1000,
      elasticity: 1000,
      easing: 'easeOutExpo',
    })
    .add({
      targets: secondaryEl,
      r: [2, 20, 50],
      opacity: [0.2, 0.3, 0],
      duration: 1000,
      offset: 100,
      easing: 'easeOutQuad',
      elasticity: 100,
    })
    .add({
      targets: textEl,
      opacity: [0, 1],
      duration: 200,
      offset: '-100',
      easing: 'linear',
    })
}

const animateClick = (element) => {
  anime({
    targets: element.secondaryEl,
    r: [20, 50],
    opacity: [0.2, 0.1, 0],
    duration: 500,
    easing: 'easeOutQuad',
    elasticity: 100,
  })
}

const textStyle = {
  letterSpacing: '0.02em',
  fill: WHITE_COLOR,
  fontFamily: FONT_SANS,
  fontSize: '0.5rem',
  textTransform: 'uppercase',
  cursor: 'default',
}

class AnimatedContents extends Component {
  componentDidMount () {
    const { delay } = this.props
    //console.log('[AnimatedDrop] componentDidAppear')
    setTimeout(() => {
      animateIn(this.primaryEl, this.secondaryEl, this.textEl)
    }, delay)
  }

  handleClick = () => {
    animateClick(this)
  }

  render () {
    return (
      <span {...this.props} onMouseEnter={this.handleClick}>
        <svg viewBox='0 0 100 100'>
          <circle
            r='0'
            cx='50%'
            cy='50%'
            fill={ACCENT1_COLOR}
            ref={(c) => {
              this.primaryEl = c
            }}
          />
          <circle
            r='0'
            cx='50%'
            cy='50%'
            fill={ACCENT1_COLOR}
            ref={(c) => {
              this.secondaryEl = c
            }}
          />
          <text
            textAnchor='middle'
            dominantBaseline='central'
            x='50%'
            y='50%'
            style={textStyle}
            ref={(c) => {
              this.textEl = c
            }}
          >
            {this.props.item}
          </text>
        </svg>
      </span>
    )
  }
}

const AnimatedDrop = ({ item = undefined, delay = 0}) => <AnimatedContents key={item} item={item} delay={delay} />

export default AnimatedDrop
