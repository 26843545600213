import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'
import { ACCENT1_COLOR } from '../services/constants'

const widthMap = {
  small: '33%',
  medium: '50%',
  large: '80%',
  full: '100%',
}

const getWidth = (props) => {
  const { small, medium, large } = props

  if (small) {
    return widthMap['small']
  }
  if (medium) {
    return widthMap['medium']
  }
  if (large) {
    return widthMap['large']
  }
  return widthMap['full']
}

const Divider = (props) => (
  <Box width={getWidth(props)} mx='auto' my={4}>
    <svg viewBox='0 0 400 10'>
      <circle cx='2.5' cy='5' r='2.5' fill={ACCENT1_COLOR} />
      <circle cx='397.5' cy='5' r='2.5' fill={ACCENT1_COLOR} />
      <line
        x1='5'
        x2='400'
        y1='5'
        y2='5'
        stroke={ACCENT1_COLOR}
        strokeWidth='1'
      />
    </svg>
  </Box>
)

Divider.propTypes = {
  small: PropTypes.bool,  
  medium: PropTypes.bool,  
  large: PropTypes.bool,  
  full: PropTypes.bool,  
}

export default Divider
