import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Heading } from 'rebass/styled-components';

// Styled component
const StyledHeading = styled(Heading)`
  padding: 0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  margin-top: 0;
  letter-spacing: ${(props) => props.theme.letterSpacing};
  line-height: 1.1;
  color: ${(props) =>
    props.white ? props.theme.colors.white : props.theme.colors.primary};
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights[1]};
`;

// Functional component
const SectionHeading = ({ children, center, ...props }) => (
    <StyledHeading
        as={Heading} // Dynamically render the `Heading` component
        center={center ? 1 : 0}
        fontSize={[5, 6]}
        my={3}
        {...props}
    >
      {children}
    </StyledHeading>
);

SectionHeading.propTypes = {
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.string.isRequired,
      white: PropTypes.string.isRequired,
      accent1: PropTypes.string.isRequired,
    }),
    fontWeights: PropTypes.arrayOf(PropTypes.number),
  }),
  white: PropTypes.bool,
  accent: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.any,
};

export default SectionHeading;