import { Heading } from 'rebass/styled-components'
import styled from 'styled-components'
import PropTypes from 'prop-types'

  const StyledHeadline = styled(Heading)`
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.2;
    font-weight: ${(props) => props.theme.fontWeights[0]};
    text-align: ${(props) => props.textAlign || 'unset'};
  `
const Headline = (props) => {
  return (
    <StyledHeadline my={4} fontSize={[5, 7]} {...props}>
      {props.children}
    </StyledHeadline>
  )
}

Headline.propTypes = {
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }),
    fontWeights: PropTypes.array,
  }),
  children: PropTypes.any,
}

export default Headline
